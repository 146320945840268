$avatar-width: 2.5rem;
$avatar-height: 2.5rem;
$avatar-status-width: 0.6666666667rem;
$avatar-status-height: 0.6666666667rem;

.avatar {
  width: $avatar-width;
  height: $avatar-height;

  .avatar-status {
    width: $avatar-status-width;
    height: $avatar-status-height;
  }
}
