.contentSection {
    
}

.contentSection::before {
    content: "";
    background-image: url('../../public/pumpjack.png');
    background-size: cover;
    /*object-fit: cover;*/
    position: absolute;
    top: 50px;
    right: 0px;
    bottom: 47px;
    left: 0px;
    opacity: 0.75;
    z-index: 10;
}

@media only screen and (max-width: 767px) {
    .contentSection::before {
        content: "";
        background-image: url('../../public/pumpjack.png');
        background-size: cover;
        /*object-fit: cover;*/
        position: absolute;
        top: 50px;
        right: 0px;
        bottom: 64px;
        left: 0px;
        opacity: 0.75;
        z-index: 10;
    }
}
